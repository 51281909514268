exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-annual-returns-js": () => import("./../../../src/pages/annual-returns.js" /* webpackChunkName: "component---src-pages-annual-returns-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-fair-practice-code-js": () => import("./../../../src/pages/fair-practice-code.js" /* webpackChunkName: "component---src-pages-fair-practice-code-js" */),
  "component---src-pages-grievance-redressal-js": () => import("./../../../src/pages/grievance-redressal.js" /* webpackChunkName: "component---src-pages-grievance-redressal-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-interest-rate-policy-js": () => import("./../../../src/pages/interest-rate-policy.js" /* webpackChunkName: "component---src-pages-interest-rate-policy-js" */),
  "component---src-pages-kyc-aml-policy-js": () => import("./../../../src/pages/kyc-aml-policy.js" /* webpackChunkName: "component---src-pages-kyc-aml-policy-js" */),
  "component---src-pages-list-of-lsps-js": () => import("./../../../src/pages/list-of-lsps.js" /* webpackChunkName: "component---src-pages-list-of-lsps-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sma-and-npa-classification-js": () => import("./../../../src/pages/sma-and-npa-classification.js" /* webpackChunkName: "component---src-pages-sma-and-npa-classification-js" */),
  "component---src-pages-terms-of-use-js": () => import("./../../../src/pages/terms-of-use.js" /* webpackChunkName: "component---src-pages-terms-of-use-js" */),
  "component---src-pages-vehicles-js": () => import("./../../../src/pages/vehicles.js" /* webpackChunkName: "component---src-pages-vehicles-js" */)
}

